<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">
        <router-link :to="{name: 'charts.users'}" class="card text-decoration-none shadow scale-hover">
          <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
              <div class="align-self-center">
                <i class="fas fa-users text-info fa-3x"></i>
              </div>
              <div class="text-end">
                <h3></h3>
                <p class="mb-0">المستخدمين</p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
      <!--        <div class="card pointer shadow scale-hover">-->
      <!--          <div class="card-body">-->
      <!--            <div class="d-flex justify-content-between px-md-1">-->
      <!--              <div class="align-self-center">-->
      <!--                <i class="far fa-comment-alt text-warning fa-3x"></i>-->
      <!--              </div>-->
      <!--              <div class="text-end">-->
      <!--                <h3>156</h3>-->
      <!--                <p class="mb-0">New Comments</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
      <!--        <div class="card pointer shadow scale-hover">-->
      <!--          <div class="card-body">-->
      <!--            <div class="d-flex justify-content-between px-md-1">-->
      <!--              <div class="align-self-center">-->
      <!--                <i class="fas fa-chart-line text-success fa-3x"></i>-->
      <!--              </div>-->
      <!--              <div class="text-end">-->
      <!--                <h3>64.89 %</h3>-->
      <!--                <p class="mb-0">Bounce Rate</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
      <!--        <div class="card pointer shadow scale-hover">-->
      <!--          <div class="card-body">-->
      <!--            <div class="d-flex justify-content-between px-md-1">-->
      <!--              <div class="align-self-center">-->
      <!--                <i class="fas fa-map-marker-alt text-danger fa-3x"></i>-->
      <!--              </div>-->
      <!--              <div class="text-end">-->
      <!--                <h3>423</h3>-->
      <!--                <p class="mb-0">Total Visits</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--    <div class="row">-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-danger">278</h3>-->
    <!--                <p class="mb-0">New Projects</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="fas fa-rocket text-danger fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-success">156</h3>-->
    <!--                <p class="mb-0">New Clients</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="far fa-user text-success fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-warning">64.89 %</h3>-->
    <!--                <p class="mb-0">Conversion Rate</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="fas fa-chart-pie text-warning fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-info">423</h3>-->
    <!--                <p class="mb-0">Support Tickets</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="far fa-life-ring text-info fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="row">-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-info">278</h3>-->
    <!--                <p class="mb-0">New Posts</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="fas fa-book-open text-info fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="px-md-1">-->
    <!--              <div class="progress mt-3 mb-1 rounded" style="height: 7px;">-->
    <!--                <div class="progress-bar bg-info" role="progressbar" style="width: 80%;" aria-valuenow="80"-->
    <!--                     aria-valuemin="0" aria-valuemax="100"></div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-warning">156</h3>-->
    <!--                <p class="mb-0">New Comments</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="far fa-comments text-warning fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="px-md-1">-->
    <!--              <div class="progress mt-3 mb-1 rounded" style="height: 7px;">-->
    <!--                <div class="progress-bar bg-warning" role="progressbar" style="width: 35%;" aria-valuenow="35"-->
    <!--                     aria-valuemin="0" aria-valuemax="100"></div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-success">64.89 %</h3>-->
    <!--                <p class="mb-0">Bounce Rate</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="fas fa-mug-hot text-success fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="px-md-1">-->
    <!--              <div class="progress mt-3 mb-1 rounded" style="height: 7px;">-->
    <!--                <div class="progress-bar bg-success" role="progressbar" style="width: 60%;" aria-valuenow="60"-->
    <!--                     aria-valuemin="0" aria-valuemax="100"></div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-sm-6 col-12 mb-2 p-2">-->
    <!--        <div class="card pointer shadow scale-hover">-->
    <!--          <div class="card-body">-->
    <!--            <div class="d-flex justify-content-between px-md-1">-->
    <!--              <div>-->
    <!--                <h3 class="text-danger">423</h3>-->
    <!--                <p class="mb-0">Total Visits</p>-->
    <!--              </div>-->
    <!--              <div class="align-self-center">-->
    <!--                <i class="fas fa-map-signs text-danger fa-3x"></i>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="px-md-1">-->
    <!--              <div class="progress mt-3 mb-1 rounded" style="height: 7px;">-->
    <!--                <div class="progress-bar bg-danger" role="progressbar" style="width: 40%;" aria-valuenow="40"-->
    <!--                     aria-valuemin="0" aria-valuemax="100"></div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <router-view />
</template>

<script>
export default {

}

</script>

<style scoped>
.scale-hover {
  transition: transform 0.5s;
}

.scale-hover:hover {
  transform: scale(1.1);
}
</style>